import React, { useState, useEffect } from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import PostHero from "../components/post/hero"
import GatedForm from "../components/gatedForm"
import ShareButton from "../components/shareButton";
import { useCookies } from 'react-cookie';

import "../styles/post.css"


export const query = graphql`
  query($id: String) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
      uri
      seo {
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        twitterDescription
        twitterImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        twitterTitle
      }
      author {
        node {
          firstName
          lastName
        }
      }
      categories {
        nodes {
          name
        }
      }
      date(formatString: "MM-DD-YYYY")
      resources {
        pdfShareLink {
          title
          altText
          localFile {
            publicURL
          }
        }
        isGated
        gravityFormsId
        previewHeadline
        previewButtonLabel
        featuredImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1096, height: 576)
            }
          }
        }
        showVideo
        videoId

      }
    }
  }
`


const WpPost = ({  data: {
    wpPost: { title, content, id, date, author, resources, uri, seo },
  }}) => {

    const [isVisible, setIsVisible] = useState(true);
    const [isSuccess, setisSuccess] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['isgated-'+resources.gravityFormsId]);

    useEffect(() => {
      if(resources.isGated) {
        if(cookies['isgated-'+resources.gravityFormsId] === 'true' && !isSuccess) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    }, [cookies]);

    function toggleVisible() {
      if(!isVisible) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    function closeGated(val) {
      setIsVisible(true);
      setisSuccess(true);
    }

  return (
    <Layout>
      {seo
        &&
        <Seo title={seo.title} description={seo.metaDesc} seo={seo} />
      }

      <PostHero
        title={title}
        date={date}
        author={author}
        image={resources.featuredImage}
        playVideo={resources.showVideo}
        video={resources.videoId}
      />

      {isVisible
        &&
        <div class="wrapper post-wrapper block-vw">

          <div className="d-md-flex row">
            <div className="col-md-3 hide-md">

              <ShareButton uri={uri} pdf={resources.pdfShareLink}/>

              <div className="margin-top--32">
                <Link to="/resources" className="go-back">Go Back</Link>
              </div>
            </div>

            <div className="col-md-9">
              <div className="post-content" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      }


      {!isVisible
        &&
        <GatedForm formId={resources.gravityFormsId} resources={resources} closeGate={closeGated} />
      }

      <div className="block-vw--top"></div>
    </Layout>
  )
}

export default WpPost
