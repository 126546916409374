import React, { useState } from "react";

import Modal from 'react-modal';

const ShareButton = (props) => {
    const [modalIsOpenShare, setIsOpenShare] = React.useState(false);

    function openModalShare() {
      setIsOpenShare(true);
    }

    function closeModalShare() {
      setIsOpenShare(false);
    }

    Modal.setAppElement('#___gatsby');

    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboard = e => {
      try {
        navigator.clipboard.writeText(window.location.toString())
        setCopySuccess('Copied!');
      } catch (err) {
        setCopySuccess('Failed to copy!');
      }
    }

    return (
        <div>
            {props.pdf &&
             <a className="btn btn-black" href={props.pdf.localFile.publicURL} target="_blank" rel="noreferrer">Download PDF</a>
            }
              <a  className="btn btn-black margin-top--32" onClick={() => openModalShare()}>Share</a>

              <Modal
              isOpen={modalIsOpenShare}
              onRequestClose={closeModalShare}
              className="Modal d-md-flex wrapper wrapper-md"
              overlayClassName="Overlay new-scrollbar-y d-flex align-items-center justify-content-center share-modal"
              contentLabel="Example Modal"
              >
              <div className="modal-close" onClick={closeModalShare}></div>
              <span className="share-link-url color-white" onClick={copyToClipboard} >https://www.nanolumens.com{props.uri}</span>
             <div className="d-block text-white copy-success">{copySuccess}</div>
            </Modal>

        </div>
    )
}

export default  ShareButton
