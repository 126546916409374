// import * as React from "react"
import React, { useState } from "react";
import { useCookies } from 'react-cookie';
import GravityForm from "react-gravity-form";
import Modal from 'react-modal'
import "../styles/gated-form.css";

const GatedForm = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(['isgated']);
  const [showForm, setShowForm] = useState(false);

  function setTheCookie(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCookie('isgated-' + props.formId, true)
    props.closeGate(false)
  }

  return (
    <div className="gated-modal">
      <div className="relative d-flex justify-content-center align-items-center h-100">
        <div className="scroll-gate">
          <div className="gated-inner">
              {!showForm &&
                <div className="form-wrapper align-center gate-preview d-flex justify-content-center align-items-center">
                  <header className="w-330 m-auto">
                    <h3 className="font-w-300" dangerouslySetInnerHTML={{ __html: props.resources.previewHeadline }}></h3>
                    {props.resources.previewButtonLabel  &&
                      <button className="margin-top--32 btn btn-black w-100" onClick={() => setShowForm(true)}>{props.resources.previewButtonLabel}</button>
                    }

                  </header>
                </div>
              }

              {showForm &&
                <GravityForm
                    backendUrl="https://wp.nanolumens.com/wp-json/glamrock/v1/gf/forms"
                    formID={props.formId}
                    onSubmitSuccess={setTheCookie}
                />
              }
          </div>
        </div>
        </div>
    </div>
  );

};

export default GatedForm;
