import React ,{useState} from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import moment from 'moment'
import ModalVideo from 'react-modal-video'

import "react-modal-video/css/modal-video.min.css"

import "../../styles/hero.css"

const isBrowser = () => typeof window !== 'undefined';

const PostHero = (props) => {

  function formatePostDate(date) {
    //console.log(date);
    let newDate = moment(date, 'MM-DD-YYYY');
    return newDate.format("MMMM DD, YYYY");

  }

  const [isOpen, setOpen] = useState(false)


  return (
    <section className="hero hero-post bg-black relative">
      <div>
        <div className="wrapper wrapper-post-hero relative">
          <h1 className="color-white font-w-300 heading-lrg" data-sal="slide-up" data-sal-delay="100" data-sal-duration="400" data-sal-easing="ease">
          {props.title}
          </h1>
          <div className="d-sm-flex margin-top--56 color-white-50" data-sal="slide-up" data-sal-delay="400" data-sal-duration="400" data-sal-easing="ease">
            <div>{props.author.node.firstName} {props.author.node.lastName}</div>
            <div className="postdate">{formatePostDate(props.date)}</div>
          </div>
        </div>
        <div className="hero-post-img-wrapper">
          <div className="hero-post-img">
          {props.image &&
            <GatsbyImage image={props.image.localFile.childImageSharp.gatsbyImageData} alt={props.image.altText} data-sal="slide-up" data-sal-delay="400" data-sal-duration="400" data-sal-easing="ease" />
          }
            {props.playVideo &&
            <div className="video-wrap">
              <div   onClick={()=> setOpen(true)} className="play-video" data-sal="slide-up" data-sal-delay="900" data-sal-duration="400" data-sal-easing="ease"><img src={'/play-btn.svg'} alt="Play Video" />


              </div>
              { isBrowser () &&
              <div className="modal-wrapper">
              <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId={props.video} onClose={() => setOpen(false)} />
              </div>
              }
            </div>
            }
          </div>
        </div>
      </div>
      </section>
  )
}



export default PostHero
